import { CSSProperties } from 'react';

import clampBuilder from '@/lib/component/clamp-builder';
import DynamicComponent from '@/storyblok/components/DynamicComponent';

import styles from './TypefaceHero.module.scss';

type Props = {
  children: [React.ReactNode];
  Headline: string;
  CustomHeadline?: any;
  HeadlineFont: string;
  desktopFontSize: string;
  mobileFontSize: string;
  SubHeadline?: string;
  SubHeadlineFont?: string;
  ColorBackgroundPreset?: string;
  ColorPrimaryPreset?: string;
};

const TypefaceHero = ({
  children,
  Headline,
  CustomHeadline,
  HeadlineFont,
  SubHeadline,
  SubHeadlineFont,
  desktopFontSize,
  mobileFontSize,
  ColorBackgroundPreset,
  ColorPrimaryPreset
}: Props) => {
  function createStyles(fontName) {
    return {
      __html: `
      :root {
        --typeface-hero-font: '${fontName}';
        --typeface-sub-hero-font: '${SubHeadlineFont}';
        --typeface-hero-desktop-size: ${desktopFontSize}px;
        --typeface-hero-mobile-size: ${mobileFontSize}px;
        --typeface-hero-clamp: ${clampBuilder(425, 1280, mobileFontSize, desktopFontSize)}
      }
    `
    };
  }

  const style: CSSProperties = {};
  if (ColorBackgroundPreset) style['--page-theme-background'] = ColorBackgroundPreset;
  if (ColorPrimaryPreset) style['--page-theme-primary'] = ColorPrimaryPreset;

  return (
    <div className={styles.TypefaceHero} style={style}>
      <style dangerouslySetInnerHTML={createStyles(HeadlineFont)} />
      <div className={styles.TypefaceHero__text}>
        {CustomHeadline
          ? CustomHeadline?.length > 0 &&
            CustomHeadline?.map((item) => <DynamicComponent key={item._uid} blok={item} />)
          : null}
        {Headline && (
          <h1>
            {Headline}
            {SubHeadline && <div>{SubHeadline}</div>}
          </h1>
        )}
      </div>
      <div className={styles.TypefaceHero__features}>
        {children?.length > 0 && <div className={styles.inner}>{children}</div>}
      </div>
    </div>
  );
};

export default TypefaceHero;
