const clampBuilder = (minWidthPx, maxWidthPx, minFontSize, maxFontSize) => {
  // Assume 16px per REM
  const pixelsPerRem = 16;

  const minFontSizeInRem = parseFloat((minFontSize / pixelsPerRem).toPrecision(4));
  const maxFontSizeInRem = parseFloat((maxFontSize / pixelsPerRem).toPrecision(4));

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSizeInRem - minFontSizeInRem) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSizeInRem;

  return `clamp( ${minFontSizeInRem}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSizeInRem}rem )`;
};

export default clampBuilder;
