import React from 'react';

import TypefaceHero from '@/components/typeface/TypefaceHero';

import DynamicComponent from './DynamicComponent';

const TypefaceHeroWrapper = ({
  blok: {
    featuresGrid,
    Headline,
    CustomHeadline,
    HeadlineFont,
    SubHeadline,
    SubHeadlineFont,
    desktopFontSize,
    mobileFontSize,
    ColorBackgroundPreset,
    ColorPrimaryPreset
  }
}) => (
  <TypefaceHero
    Headline={Headline}
    HeadlineFont={HeadlineFont}
    CustomHeadline={CustomHeadline}
    desktopFontSize={desktopFontSize}
    mobileFontSize={mobileFontSize}
    SubHeadline={SubHeadline}
    SubHeadlineFont={SubHeadlineFont}
    ColorBackgroundPreset={ColorBackgroundPreset}
    ColorPrimaryPreset={ColorPrimaryPreset}
  >
    {featuresGrid.map((blok) => (
      <DynamicComponent blok={blok} key={blok._uid} />
    ))}
  </TypefaceHero>
);
export default TypefaceHeroWrapper;
